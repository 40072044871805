// 落地也各组件的正则配置。
export default [
    {
        component: 'v-input',
        rules: {
            required: true,
            message: '请输入',
            trigger: 'blur',
        },
    },
    {
        component: 'v-text',
        rules: {
            required: true,
            message: '请输入',
            trigger: 'blur',
        },
    },
    {
        component: 'v-formtext',
        rules: {
            required: true,
            message: '请输入',
            trigger: 'blur',
        },
    },
    {
        component: 'v-radio',
        rules: {
            required: true,
            message: '请选择',
            trigger: 'change',
        },
    },
    {
        component: 'v-radioage',
        rules: {
            required: true,
            message: '请选择',
            trigger: 'change',
        },
    },
    {
        component: 'v-privacy',
        rules: {
            required: true,
            message: '请选择',
            trigger: 'change',
        },
    },
    {
        component: 'v-PhoneText',
        rules: {
            required: true,
            message: '请输入',
            trigger: 'blur',
        },
    },
]