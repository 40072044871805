// 公共样式
export const commonStyle = {
    rotate: 0,
    opacity: 1,
}

export const commonAttr = {
    animations: [],
    events: {},
    groupStyle: {}, // 当一个组件成为 Group 的子组件时使用
    isLock: false, // 是否锁定组件
}

// 表单组件文件，用于筛选页面组件为 表单类型的组件，用于提交数据。

// 编辑器左侧组件列表
const list = [
    // 文本
    {
        component: 'v-input',
        label: '姓名',
        imgUrl: require('@/assets/images/name.png'),
        activeImgUrl: require('@/assets/images/active-name.png'),
        propValue: {
            title: "姓名",
            required: 1,
            orderType: 1,
            itemName: '',
            assemblyType: 3,
            controlType: 4,
            type: 1
        },
        icon: 'wenben',
        style: {
            // width: 200,
            // height: 22,
            // fontSize: 14,
            // fontWeight: 500,
            // lineHeight: '',
            // letterSpacing: 0,
            // textAlign: '',
            // color: '',
        },
    },
    {
        component: 'v-PhoneText',
        label: '手机',
        mark: "PhoneRef",
        imgUrl: require('@/assets/images/phone.png'),
        activeImgUrl: require('@/assets/images/phone1.png'),
        propValue: {
            title: "手机(已加密)",
            required: 1,
            orderType: 1,
            itemName: '',
            assemblyType:4,
            controlType: 4,
            type: 1,
            isSend:false
        },
        icon: 'wenben',
        style: {
            buttonColor:"",//按钮颜色
            buttonFontSize:"",//按钮文字大小
            buttonFontColor:"",//按钮字体颜色
            // width: 200,
            // height: 22,
            // fontSize: 14,
            // fontWeight: 500,
            // lineHeight: '',
            // letterSpacing: 0,
            // textAlign: '',
            // color: '',
        },
    },
    {
        component: 'v-radio',
        label: '性别',
        imgUrl: require('@/assets/images/Gender.png'),
        activeImgUrl: require('@/assets/images/aactive-gender.png'),
        propValue: {
            title: "性别",
            required: 1,
            arrangementMode: 1,
            assemblyType: 5,
            controlType: 4,
            type: 1
        },
        icon: 'wenben',
        style: {
            // width: 200,
            // height: 22,
            // fontSize: 14,
            // fontWeight: 500,
            // lineHeight: '',
            // letterSpacing: 0,
            // textAlign: '',
            // color: '',
        },
    },
    {
        component: 'v-radioage',
        label: '年龄',
        imgUrl: require('@/assets/images/age.png'),
        activeImgUrl: require('@/assets/images/active-age.png'),
        propValue: {
            title: "年龄", required: 1,
            option: [
                {
                    label: 0,
                    title: "17岁及以下报名无效"
                },
                {
                    label: 1,
                    title: "18～20岁"
                },
                {
                    label: 2,
                    title: "21～30岁"
                },
                {
                    label: 3,
                    title: "30岁以上"
                }
            ],
            assemblyType: 6,
            controlType: 4,
            type: 1
        },
        icon: 'wenben',
        style: {
            // width: 200,
            // height: 22,
            // fontSize: 14,
            // fontWeight: 500,
            // lineHeight: '',
            // letterSpacing: 0,
            // textAlign: '',
            // color: '',
        },
    },
    {
        component: 'v-radio',
        label: '学历',
        imgUrl: require('@/assets/images/education.png'),
        activeImgUrl: require('@/assets/images/active-education.png'),
        propValue: {
            title: "学历", required: 1,
            option: [
                {
                    label: 0,
                    title: "小学及已下"
                },
                {
                    label: 1,
                    title: "初中"
                },
                {
                    label: 2,
                    title: "高中/中专/技校"
                },
                {
                    label: 3,
                    title: "大学专科"
                },
                {
                    label: 4,
                    title: "大学本科"
                },
                {
                    label: 5,
                    title: "硕士研究生及以上"
                }
            ],
            assemblyType: 7,
            controlType: 4,
            type: 1
        },
        icon: 'wenben',
        style: {
            // width: 200,
            // height: 22,
            // fontSize: 14,
            // fontWeight: 500,
            // lineHeight: '',
            // letterSpacing: 0,
            // textAlign: '',
            // color: '',
        },
    },
    {
        component: 'v-radio',
        label: '职业',
        imgUrl: require('@/assets/images/occupation.png'),
        activeImgUrl: require('@/assets/images/active-occupation.png'),
        propValue: {
            title: "职业", required: 1,
            option: [
                {
                    label: 0,
                    title: "在校学生"
                },
                {
                    label: 1,
                    title: "上班族"
                },
                {
                    label: 2,
                    title: "自由职业"
                },
                {
                    label: 3,
                    title: "其他职业"
                }
            ],
            assemblyType: 8,
            controlType: 4,
            type: 1
        },
        icon: 'wenben',
        style: {
            // width: 200,
            // height: 22,
            // fontSize: 14,
            // fontWeight: 500,
            // lineHeight: '',
            // letterSpacing: 0,
            // textAlign: '',
            // color: '',
        },
    },
    {
        component: 'v-radio',
        label: '学习目标',
        imgUrl: require('@/assets/images/target.png'),
        activeImgUrl: require('@/assets/images/active-target.png'),
        propValue: {
            title: "学习目标", required: 1,
            option: [
                {
                    label: 0,
                    title: "兴趣爱好"
                },
                {
                    label: 1,
                    title: "工作需要"
                },
                {
                    label: 2,
                    title: "兼职副业"
                },
                {
                    label: 3,
                    title: "其他需要"
                }
            ],
            assemblyType: 9,
            controlType: 4,
            type: 1
        },
        icon: 'wenben',
        style: {
            // width: 200,
            // height: 22,
            // fontSize: 14,
            // fontWeight: 500,
            // lineHeight: '',
            // letterSpacing: 0,
            // textAlign: '',
            // color: '',
        },
    },
    {
        component: 'v-formtext',
        label: '文本框',
        imgUrl: require('@/assets/images/text.png'),
        activeImgUrl: require('@/assets/images/active-text.png'),
        propValue: {
            title: "文本框",
            required: 0,
            arrangementModetext: 1,
            changerow: '单行',
            assemblyType: 13,
            controlType: 4,
            type: 1,
            itemName: ""
        },
        icon: 'wenben',
        style: {
            // width: 200,
            // height: 22,
            // fontSize: 14,
            // fontWeight: 500,
            // lineHeight: '',
            // letterSpacing: 0,
            // textAlign: '',
            // color: '',
        },
    },
    {
        component: 'v-formtext',
        label: '文本框',
        imgUrl: require('@/assets/images/text.png'),
        activeImgUrl: require('@/assets/images/active-text.png'),
        propValue: {
            title: "文本框",
            required: 0,
            arrangementModetext: 1,
            changerow: '单行',
            assemblyType: 13,
            controlType: 4,
            type: 1,
            itemName: ""
        },
        icon: 'wenben',
        style: {
            // width: 200,
            // height: 22,
            // fontSize: 14,
            // fontWeight: 500,
            // lineHeight: '',
            // letterSpacing: 0,
            // textAlign: '',
            // color: '',
        },
    },
]

for (let i = 0, len = list.length; i < len; i++) {
    const item = list[i]
    item.style = { ...commonStyle, ...item.style }
    list[i] = { ...commonAttr, ...item }
}

export default list