import { getStyle } from '../utils/style'
import { testPhone, getQueryVariable } from '../utils/index'
import { submitForm, submitPay, getFlowPageById, getWeChatCode } from '../api/index'
import list from '../custom-component/formLists/form-list.js'
import formRules from '../config/form-rules'
export default {
    methods: {
        // 请求落地页表单组件
        getFlowPageById(id) {
            return getFlowPageById(id).then(res => {
                if (res.code == 'SUCCESS') {
                    document.title = res.data.title;//设置页面顶部标题
                    this.$store.commit('setPerview', false);
                    this.$store.commit('setID', id);
                    this.$store.commit('setFlowPageAllData', res.data);
                    this.$store.commit('setComponentData', res.data.flowPageFormVOList);
                    this.setFormValidator(this.pageComponentData);
                } else {
                    this.$message.error('加载失败,请稍后重试！');
                }
            });
        },

        // 设置表单验证规则
        setFormValidator(PageComponentData) {
            // 手机号验证
            const validatePhone = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请输入手机号"));
                } else {
                    if (!testPhone(value)) {
                        callback(new Error("请输入正确的手机号"));
                    } else {
                        callback();
                    }
                }
            };
            let rules = {},
                ruleForm = {};
            PageComponentData.map(item => {
                if (item.propValue.required != 0) { //必填项
                    formRules.map(ruleItem => {
                        if (ruleItem.component === item.component) {
                            let rule = ruleItem.rules;
                            if (item.component === 'v-PhoneText') {
                                rules['formItem_phone_' + item.id] = [
                                    { required: true, message: '请输入手机号', trigger: 'blur' },
                                    { validator: validatePhone, trigger: "blur" }
                                ]
                                ruleForm['formItem_phone_' + item.id] = '';
                                if (item.propValue.isSend) {//手机验证码开启
                                    rules['formItem_code_' + item.id] = [
                                        { required: true, message: '请输入验证码', trigger: 'blur' }
                                    ]
                                    ruleForm['formItem_code_' + item.id] = '';
                                }
                            } else {
                                rule.message = rule.message + item.propValue.title;
                                rules['formItem_' + item.id] = [rule];
                                ruleForm['formItem_' + item.id] = '';
                            }

                        }
                    })
                }
            })
            this.rules = rules;
            this.ruleForm = ruleForm;
        },
        // 获取组件样式
        getComponentStyle(style) {
            return getStyle(style, ['top', 'left', 'width', 'height', 'rotate'])
        },
        // 获取表单数据
        getFormData(PageComponentData, ruleForm) {
            // 提取选项名称值
            const getOption = (flowPageFormItemVOList, value) => {
                return flowPageFormItemVOList.filter(OpItem => OpItem.id === value)[0].content
            }
            let data = {
                flowName: null,
                flowId: this.$store.state.userDatabaseForm.flowId ? this.$store.state.userDatabaseForm.flowId : null,
                flowNotFinishId: null,
                formAnswerInputList: [],
                flowPageId: Number(this.$store.state.id),
                mobile: "",
                verificationCode: "",
                clickid: this.$store.state.urlParams.clickid,// 巨量引擎参数
            }
            PageComponentData.map(item => {
                // 打开宙斯、开启星汉、调取落地页组件数据
                if (list.some(listItem => listItem.component === item.component)) {//在表单配置里面有这个组件
                    let flowPageFormItemId = '', flowPageFormItemName = '';
                    if (item.flowPageFormItemVOList && item.flowPageFormItemVOList.length > 0) {// 表单某一项是选择项，获取选择项ID。
                        flowPageFormItemId = ruleForm['formItem_' + item.id];
                        flowPageFormItemName = getOption(item.flowPageFormItemVOList, ruleForm['formItem_' + item.id]) // 获取选择项文本内容
                    } else {//是输入框的值
                        flowPageFormItemName = ruleForm['formItem_' + item.id];// 获取获取输入框内容
                        if (item.propValue.title.indexOf('姓名') !== -1) {//是姓名
                            data.flowName = ruleForm['formItem_' + item.id]
                        }
                    }
                    // 提取手机号和验证码。
                    if (item.component === 'v-PhoneText') {
                        data.mobile = ruleForm['formItem_phone_' + item.id].toString();
                        if (item.propValue.isSend) {//手机验证码开启
                            data.verificationCode = ruleForm['formItem_code_' + item.id].toString();
                        }
                    } else {
                        // 组合后端POST数据
                        data.formAnswerInputList.push({
                            flowPageFormId: item.id,
                            flowPageFormItemId: flowPageFormItemId,
                            flowPageFormItemName: flowPageFormItemName,
                            flowPageFormName: item.propValue.title
                        })
                    }
                }
            })
            return data;
        },
        // 提交操作
        submitBtn(ComponentData = [], prop) {
            this.$refs['ruleForm'].validate(async (valid) => {
                if (valid) {
                    if (this.$store.state.flowPage.perview) {//预览操作
                        this.navigationTo();//落地页模式跳转
                    } else {//用户落地页操作
                        this.submitFormData(ComponentData).then(async (res) => {
                            let sesameLink = await this.getSesameLink(res.data.flowId)
                            this.$message.success('操作成功')
                            let wxCode = await getWeChatCode({ flowId: res.data.flowId })
                            if (prop && prop.addTeacherWechat === 0 && wxCode.data) {
                                const a = document.createElement('a')
                                a.href = sesameLink.data
                                a.click()
                                return
                            }
                            this.navigationTo();
                        });
                        // console.log(this.$store.state.flowPage.formAllAnswer.flowId, "ComponentData.flowId")
                        // if (this.$store.state.flowPage.formAllAnswer.flowId) {
                        //     let flowId = this.$store.state.flowPage.formAllAnswer.flowId
                        //     let sesameLink = await this.getSesameLink(flowId)
                        //     this.$message.success('操作成功')
                        //     let wxCode = await getWeChatCode({ flowId: flowId })
                        //     if (prop && prop.addTeacherWechat === 0 && wxCode.data) {
                        //         const a = document.createElement('a')
                        //         a.href = sesameLink.data
                        //         a.click()
                        //         return
                        //     }
                        //     this.navigationTo();
                        // } else {
                        //     this.submitFormData(ComponentData).then(async (res) => {
                        //         let sesameLink = await this.getSesameLink(res.data.flowId)
                        //         this.$message.success('操作成功')
                        //         let wxCode = await getWeChatCode({ flowId: res.data.flowId })
                        //         if (prop && prop.addTeacherWechat === 0 && wxCode.data) {
                        //             const a = document.createElement('a')
                        //             a.href = sesameLink.data
                        //             a.click()
                        //             return
                        //         }
                        //         this.navigationTo();
                        //     });
                        // }
                    }
                } else {
                    // this.$message.error('您还有必填信息未填写！')
                    // console.log('您还有未填写的信息!!', valid);
                    return false;
                }
            });
        },
        // 提交数据
        submitFormData(ComponentData) {
            console.log(this.$store.state.userDatabaseForm, Number(this.getCourseId()))
            return new Promise((resolve, reject) => {
                let FormAllAnswer = this.getFormData(ComponentData, this.ruleForm);
                this.$store.commit('setFormAllAnswer', FormAllAnswer)
                console.log('FormAllAnswer', JSON.stringify(FormAllAnswer))
                if (FormAllAnswer.formAnswerInputList.length > 0) {//有表单数据需要提交
                    submitForm(FormAllAnswer).then((res) => {
                        if (res.code === 'SUCCESS') {
                            if (!this.$store.state.userDatabaseForm.token) {//已经有用户token了，不需要在保存
                                localStorage.setItem('userData', res.data)
                                this.$store.commit('setUserDatabaseForm', res.data);//设置用户保存数据库的表单数据
                            }

                            if (this.canPay()) {//需要去支付
                                this.submitPay(res.data);
                            } else {
                                resolve(res);
                            }
                            resolve(res);
                        } else {
                            reject(res);
                            this.$message.error(res.message ? res.message : '操作失败')

                        }
                    }).catch(err => {
                        reject(err);
                        this.$message.error('操作失败')
                        console.log(err)
                    })
                } else {
                    if (this.canPay()) {//需要去支付
                        this.submitPay(this.$store.state.userDatabaseForm);
                    } else {
                        resolve('1');
                    }
                }
            })
        },
        // 是否需要去支付
        canPay() {
            return this.pageComponentData.some(item => item.component === 'v-payall' || item.component === 'v-pay' || item.component === 'v-buttonPay')
        },
        // 去支付
        submitPay(userDatabaseForm) {
            let urlParams = this.$store.state.urlParams;
            let data = {
                courseId: Number(this.getCourseId()),
                flowId: userDatabaseForm.flowId,
                flowPageId: Number(this.$store.state.id),
                payFinishUrl: process.env.VUE_APP_SYS_URL + 'tip-page?id=' + this.$store.state.id + '&flowId=' + userDatabaseForm.flowId + '&adid=' + urlParams.adid + '&creativeid=' + urlParams.creativeid + '&creativetype=' + urlParams.creativetype + '&clickid=' + urlParams.clickid,
                payType: this.$store.state.payType,
                clickid: this.$store.state.urlParams.clickid,// 巨量引擎参数
            }

            console.log(submitPay)
            submitPay(data).then(res => {
                console.log(res);
                localStorage.setItem('payData', res.data)
                if (res.code === 'SUCCESS') {
                    if (this.$store.state.payType === 'WX') {
                        console.log(res.data.orderForm)
                        // window.location.href = res.data.orderForm;//微信是URL地址
                    } else {//
                        document.body.innerHTML = document.body.innerHTML + res.data.orderForm;//支付宝是HTML表单代码
                        for (let i = (document.forms.length - 1); i >= 0; i--) {//找到最新支付宝表单进行提交
                            if (document.forms[i].name === 'punchout_form') {
                                document.forms[i].submit()
                            }
                        }
                    }
                } else {
                    this.$message.error(res.message);
                }
            }).cath(err => {
                this.$message.error('请求失败，请稍后重试！');
                console.log(err)
            });
        },
        // 获取课程ID。
        getCourseId() {
            // 在内容页、信息页找课程组件，获取课程ID
            let pageComponentData = this.$store.state.flowPage.flowPageComponentData.concat(this.$store.state.flowPage.messagePageComponentData)
            let courseId = null;
            pageComponentData.map(item => {
                if (item.component === 'v-order' || item.component === 'v-payall' || item.component === 'v-pay') {
                    courseId = item.propValue.courseId;
                }
            })
            return courseId;
        },
        // 获取巨量引擎参数
        getUrlParams() {
            let adid = getQueryVariable('adid'),
                creativeid = getQueryVariable('creativeid'),
                creativetype = getQueryVariable('creativetype'),
                clickid = getQueryVariable('clickid');
            this.$store.commit('setUrlParams', {
                adid: adid ? Number(adid) : adid,
                creativeid: creativeid ? Number(creativeid) : creativeid,
                creativetype: creativetype ? Number(creativetype) : creativetype,
                clickid: clickid,
            })
            console.log(this.$store.state.urlParams)
        }
    },
}